import { render, staticRenderFns } from "./HomeFlexibleContent.vue?vue&type=template&id=66b9ef6c&scoped=true&"
import script from "./HomeFlexibleContent.vue?vue&type=script&lang=js&"
export * from "./HomeFlexibleContent.vue?vue&type=script&lang=js&"
import style0 from "./HomeFlexibleContent.vue?vue&type=style&index=0&id=66b9ef6c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66b9ef6c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FlexibleBlockTitle: require('/src/components/ContentBlocks/FlexibleBlockTitle.vue').default,StandardDFP: require('/src/components/DFP/Standard/StandardDFP.vue').default})
